import $ from 'jquery';
function anchor(hash) {
	if (
		location.pathname.replace(/^\//, '') == hash.pathname.replace(/^\//, '') &&
		location.hostname == hash.hostname
	) {
		let $target = $(hash.hash);
		$target =
			($target.length && $target) || $('[name=' + hash.hash.slice(1) + ']');
		let offsetTop = $('.c-header').outerHeight() - 1;
		console.log(offsetTop);
		if ($target.length) {
			let targetOffset = $target.offset().top - offsetTop;
			$('html,body').animate({ scrollTop: targetOffset }, 1000);
			return false;
		}
	}
}

$('a[href*=\\#]:not([href=\\#])').click(function () {
	var a = this;
	if ($('body').hasClass('is-fixed')) {
		$('.js-header-open ').trigger('click');
		setTimeout(function () {
			anchor(a);
		}, 300);
	} else {
		anchor(this);
	}
	return false;
});

$(window).scroll(function () {
	let scrollTop = $(this).scrollTop();
	if (scrollTop > 300) {
		$('.js-gotop').addClass('is-show');
	} else {
		$('.js-gotop').removeClass('is-show');
	}

	let heightContent = $(document).height() - 100;
	if ($(window).scrollTop() + $(window).height() > heightContent) {
		$('.js-gotop').addClass('is-bottom');
	} else {
		$('.js-gotop').removeClass('is-bottom');
	}
});

$('.js-gotop').on('click', function (e) {
	e.preventDefault();
	$('html, body').animate({ scrollTop: 0 }, 1000);
});
