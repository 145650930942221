import $ from 'jquery';
var $window = $(window);
var $bodyHtml = $('body,html');
var $html = $('html');
var $body = $('body');
var breakPoint1 = 767;

/* ======================================
body fix
====================================== */
let scrollPosi;

function bodyFix() {
	scrollPosi = $(window).scrollTop();

	$body.addClass('is-fixed');
	$body.css({ top: -scrollPosi });
}

function bodyFixReset() {
	$body.removeClass('is-fixed')
	$body.removeAttr('style')
	$bodyHtml.scrollTop(scrollPosi);
}

/* ======================================
menu SP
====================================== */
$(function () {
	$('.js-header-open').on('click', function () {
		$(this).toggleClass('is-open');
		if ($(this).hasClass('is-open')) {
			bodyFix();
			$('.js-nav').addClass('is-open');
		} else {
			bodyFixReset();
			$('.js-nav').removeClass('is-open');
		}
	});
});

$(window).on('load scroll', function () {
	$('.c-header').css('left', $(window).scrollLeft() * -1 + 'px');
});

//===================================================
// format
//===================================================
var width = $(window).width();
$(window).on('resize load', function () {
	if ($(window).outerWidth() == width) {
		return;
	} else {
		width = $(window).width();

		if ($(window).outerWidth() > breakPoint1) {
			$('body').removeAttr('style');
			$('.js-nav').removeClass('is-open');
			$('.js-header-open').removeClass('is-open');
		} else {
		}
	}
});
